import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from '../css/page.module.css'
import cardStyles from '../css/PortfolioCard.module.css'
import { useEffect, useState, useRef } from 'react';

import Introduction from '../components/Introduction';
import PortfolioCard from '../components/PortfolioCard';
import WorkExperienceCard from '../components/WorkExperienceCard';
import AwardCardContainer from '../components/AwardCardContainer';

const awardCards = [
  {
    color:"#F9F2B3",
    title:"1st place, Kuzneski Innovation Cup 2022",
    logo:"/kuzneski_logo.png",
    description:"Pitch competition at the University of Pittsburgh. $7,500 in non-dilutive early stage startup funding"
  },
  {
    color: "#96BFFD",
    title: "Nordenberg Leadership Scholarship",
    logo: "/pitt_logo.png",
    description: "Full scholarship and leadership development program at the University of Pittsburgh"
  },
  {
    color: "#C2FFC8",
    title: "HCOMP 2023 Student Travel Scholarship",
    logo: "/hcomp.png",
    description: "Awarded $1,795 to travel to Delft, Netherlands for the 2023 Conference on Human Computation and Collective Intelligence."
  },
  {
    color: "#fab770",
    title: "Lola G. Duff and William H. Duff, II Scholarship",
    logo: "/scholarship-america.png",
    description: "4-year, $24,000 merit scholarship awarded to promising Protestants graduating from high schools in Allegheny County, administered by Scholarship America"
  },
  {
    color: "#FFBBFC",
    title: "Most Technically Elegant Hack, HackMIT",
    logo: "/mit_logo.png",
    description: "Competed among 1000+ hackers nation wide on MIT's campus. Developed the Tinder for prompt refinement in image generation models."
  },
  {
    color: "#89BFFD",
    title: "Best Healthcare Hack YHack 2024",
    logo: "/yale_logo.jpeg",
    description: "Developed a bed-side universal translator for non-english speaking patients and healthcare providers at Yale University's annual hackathon."
  }
]

const cardProps = [
    {
      projectName: "raggy notebook 🐶",
      shortDescription: "I built a visual interactive notebook for iterating on RAG pipelines, leveraging TypeScript (React), Python (Flask), and OpenAI API. Designed and conducted end-to-end user-study with 12 AI engineers to probe developer workflows when iterating on RAG pipelines.",
      borderColor: `rgba(255, 100, 200, 0.732)`,
      imageAltText: "raggy notebook interface showing different intermediate steps in a RAG pipeline in a notebook style interface",
      imageLink: "/raggy.png",
      imageWidth: 390 / 1.5,
      imageHeight: 222 /1.5
    },
    {
      projectName: "BizChat 💼",
      shortDescription: "Currently building a chat-based tool for small business owners to use large-language models to generate business plans. I aim to conduct a user-study with 11 local entrepreneurs to evaluate the tool's usability and effectiveness.",
      borderColor: `rgba(147, 118, 200, 0.732)`,
      imageAltText: "BizChat interface showing chat-based and in-line affordances for small business owners to use large-language models to generate business plans",
      imageLink: "/bizchat.png",
      imageWidth: 390 / 1.5,
      imageHeight: 222 /1.5
    },
    { 
      projectName: "Simplify the Spreadsheet", 
      shortDescription: "During my internship at CMU's Human-Computer Interaction Institute, I developed a mobile inventory management application for creative entreprenuers to explore new forms of data input techniques that obfuscate away the complexities of spreadsheets.", 
      borderColor: `rgba(255, 10, 100, 0.732)`, 
      imageAltText: "TutorHHS.org website homepage",
      imageLink: "/simplify-the-spreadsheet.png",
      imageWidth: 390/ 1.7,
      imageHeight: 262 /1.7
    },
    { 
        projectName: "TutorHHS.org", 
        shortDescription: "I wanted a way for students to find tutors easily at my high school, so I created a website to match NHS students to tutors. My high school still uses it to this day and it has served over 1,200 students. Visit the website to see it in action!", 
        borderColor: `rgba(255, 100, 100, 0.732)`, 
        imageAltText: "TutorHHS.org website homepage",
        imageLink: "/tutorhhs.png",
        imageWidth: 390/ 1.7,
        imageHeight: 262 /1.7
    },
    {
        projectName: "Smart Chessboard",
        shortDescription: "Using Hall-Effect sensors, magnets microcontrollers, a RaspberryPi, a bunch of wires, too much solder, some boolean logic, and an old chess set, I created a chess board that tracks users’ moves when they play a game from the start. I'm currently working on a web-app to display the game in real-time.",
        borderColor: `rgba(247, 218, 100, 0.732)`,
        imageAltText: "Underside of the chessboard, showing wires connecting to Raspberry Pi",
        imageLink: "/chessboard.png",
        imageWidth: 364 / 1.7,
        imageHeight: 358 / 1.7
    },
];






export default function Home() {
  const navigate = useNavigate();
  const [imageIndex, setImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  

    // Inside your Home component before return statement
  const portfolioCardContainerRef = useRef(null);



  useEffect(() => {
    const updateMobileStatus = () => {
      setIsMobile(window.innerWidth <= 600);
    };
  
    const updateContainerHeight = () => {
      if (!portfolioCardContainerRef.current) return; // Guard clause if ref isn't attached
  
      let maxCardHeight = 0;
      // Get all the card elements, assume they have a class 'card'
      const cards = portfolioCardContainerRef.current.getElementsByClassName(cardStyles.card);

      for (let card of cards) {
        // Calculate max height based on card offset in the stack
        const cardStyle = card.currentStyle || window.getComputedStyle(card);
        const cardMarginBottom = parseFloat(cardStyle.marginBottom);
        // Use offsetHeight plus marginBottom as part of the height calculation
        maxCardHeight = Math.max(maxCardHeight, card.offsetHeight + cardMarginBottom);
      }
  
      // Set the container height plus some extra space (e.g., paddingBottom) if needed
      portfolioCardContainerRef.current.style.minHeight = `${maxCardHeight + 200}px`;
      portfolioCardContainerRef.current.style.paddingBottom = `${100}px`;
    };

    window.addEventListener('resize', updateMobileStatus);
    window.addEventListener('resize', updateContainerHeight);

    // Set the initial value
    updateMobileStatus();
    updateContainerHeight();
    
    return () => {
        window.removeEventListener('resize', updateMobileStatus);
        window.addEventListener('resize', updateContainerHeight);
      }
  }, []);
  
  // enables the changing image in the introduction 
  useEffect(() => {
    function updatedImageIndex(){
      if(imageIndex >= 3) setImageIndex(0);
      else setImageIndex(i => i + 1);
  }
    const intervalId = setInterval(updatedImageIndex, 700)
    return () => clearInterval(intervalId);
  })  



    // handle updating portfolio cards location
    const [zIndices, setZIndices] = useState([4, 3, 2, 1, 0]);  // initial z-indices for each card
    const handleCardClick = (clickedIndex) => {
      // If the clicked card is already at the top (has highest z-index), navigate to portfolio
      if(zIndices[clickedIndex] === Math.max(...zIndices)) {
          navigate('/portfolio');
          return;
      }

      setZIndices(zIndices => {
          // Create a copy of the zIndices array
          const newZIndices = [...zIndices];
          // Update the zIndex of each card
          newZIndices.forEach((z, i) => {
              if (i === clickedIndex) {
                  // Bring the clicked card to the front
                  newZIndices[i] = zIndices.length - 1;
              } else if (z > zIndices[clickedIndex]) {
                  // Push cards ahead back!
                  newZIndices[i] = Math.max(newZIndices[i] - 1, 0);
              }
          });
          // Return the updated zIndices array
          return newZIndices;
      });
  };

  return (
    <>
        <Introduction imageIndex={imageIndex}/>

        {/* portfolio section */}
        <div className={styles.container}>
            <h2 className={styles.sectionTitle}>a sneak peak at my portfolio:</h2>

            <div className={styles.portfolioCardContainer} ref={portfolioCardContainerRef}>
              <div className={styles.portfolioCards} >
                  {cardProps.map((props, index) => (
                      <div key={props.projectName} onClick={() => handleCardClick(index)} >
                          <PortfolioCard

                              {...props}
                              style={{
                                zIndex: zIndices[index],
                                position: 'absolute',
                                transition: 'all 0.5s ease',
                                // Conditionally apply top and left styles based on isMobile state
                                top: isMobile ? `${zIndices[index] * 20}px` : `${zIndices[index] * -25 + 150}px`,
                                left: isMobile ? `${zIndices[index] * 1 - 10}px` : `${(window.innerWidth / 4) + (zIndices[index] * -43)}px`,
                                cursor: zIndices[index] === Math.max(...zIndices) ? 'pointer' : 'default',
                                transform: zIndices[index] === Math.max(...zIndices) ? 'scale(1.02)' : 'scale(1)',
                              }}
                              data-is-top={zIndices[index] === Math.max(...zIndices)}
                              data-tooltip="Click to see full portfolio →"
                          />
                      </div>
                  ))}
              </div>
        
            </div>
        </div>
       
       {/* work history section */}
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>experience:</h2>

          <h3>currently:</h3>

          <WorkExperienceCard
            color="#fab770"
            logo="/umbc_logo.png"
            jobTitle="Research Assistant"
            jobDescription="Developing BizChat, a tool for small business owners of various digital literacy to use large-language models to generate business plans through chat-based and in-line affordances. Developing user-study to report results to CHIWORK with Prof. Yasmine Kotturi."
            jobDates="September 2024 - Present"
          />

          <h3>Summer 2024</h3>

          <WorkExperienceCard
            color="#F9F2B3"
            logo="/berkeley_logo.jpeg"
            jobTitle="Summer Research Assistant (Berkeley SUPERB Responsible Artificial Intelligence Program)"
            jobDescription="Developed a visual interactive notebook for iterating on RAG pipelines, leveraging TypeScript (React), Python (Flask), and OpenAI API. Designed and conducted end-to-end user-study with 12 AI engineers to probe developer workflows when iterating on RAG pipelines. Advised by Prof. Aditya Parameswaran and Shreya Shankar."
            jobDates="June 2024 - August 2024"
          />

          <h3>Fall 2023 - Spring 2024</h3>

          <WorkExperienceCard
            color="#C0E6FC"
            logo="/pitt_cyber_logo.jpeg"
            jobTitle="Research Assistant"
            jobDescription="Developed a case study between Ireland and United States 2024 election integrity with a particular focus on mis/disinformation. Submitted to Azure Forum for Contemporary Security Strategy. Advised by Prof. David Hickton."
            jobDates="June 2024 - August 2024"
          />

          <WorkExperienceCard
            // color="#FFBBFC"

            color="#FDBEBA"
            logo="/cmu_logo.png"
            jobTitle="Research Assistant"
            jobDescription="Led interview study with 11 local entrepreneurs to build design principles informing design of generative AI technology for small-scale business owners. First authored short paper, published at ACM Conference on Computer Supported Cooperative Work (CSCW) 2024 in Costa Rica, guided by Jeffrey Bigham and Yasmine Kotturi."
            jobDates="October 2023 - May 2024"
          />

          <h3>Summer 2023</h3>
          <WorkExperienceCard
            color="#fab770"
            logo="/amazon_logo.png"
            jobTitle="Amazon Summer Undergraduate Research Experience at CMU HCII"
            jobDescription="Developed React Native app that implements two novel input techniques for spreadsheet-esque creative product data. Built accompanying live-chat wizarding tool in React and Google Firebase to facilitate user interaction with researchers, creating an “intelligent business data partner” input method for app users."
            jobDates="May 2023 - August 2023"
          />
          {/* <WorkExperienceCard
            color="#D6FDD5"
            logo="/raspishop_logo.png"
            jobTitle="Founder"
            jobDescription="I developed www.raspishop.com, where I sell pre-configured software kits for the RaspberryPi. I served customers all over the world, including United States, Canada, Switzerland, Hungary, Slovakia, Austria, New Zealand, and Great Britain."
            jobDates="October 2020 - September 2022"
          /> */}
          {/* <WorkExperienceCard
            color="#D6FDD5"
            logo="/qrlcom_logo.jpeg"
            jobTitle="Freelance Web Developer"
            jobDescription="I built websites for a small church and a small startup in the  Pittsburgh Area: Nativity Lutheran Church and Smooth Electrolysis Studio. I managed DNS and SSL for these clients, and interfaced with various CMSs to create easy maintenance systems to fit their needs."
            jobDates="May 2022 - August 2022"
          /> */}
          {/* <WorkExperienceCard
            color="#FFBBFC"
            logo="/mathnasium_logo.jpeg"
            jobTitle="Math Instructor"
            jobDescription="I taught math to K-12 students! Anything from counting all the way to calculus."
            jobDates="May 2022 - August 2022"
          /> */}
        </div>

        {/* awards section */}
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>some awards i have won:</h2>

          <AwardCardContainer  awards={awardCards}/>

        </div>
        
        {/* extra information section */}
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>other ways i like to stay involved:</h2>
            <h3>Leadership:</h3>
            <ul>
              <li>Executive Director, <a href="http://steelhacks.com">Steel Hacks</a> 2024</li>
              <li>Organizing committee, <a href="http://pittchallenge.com">Pitt Challenge</a> 2023</li>
              <li>Organizing committee, <a href="http://steelhacks.com">Steel Hacks</a> 2023</li>
              <li>Class President & Drum Major, <a href="https://hhs.ht-sd.org">Hampton High School</a></li>
            </ul>
            <h3>Conferences Attended:</h3>
         
            <ul>
              <li>AAAI <a href="https://www.humancomputation.com/">Conference on Human Computation/Collective Intelligence</a>, 2023</li>
              <li>Association of Computing Machinery <a href="https://dis.acm.org/2023/">Designing Interactive Systems</a>, 2023</li>
              <li><a href="http://eradicatehatesummit.org/">Eradicate Hate Global Summit</a> 2022</li>
              <li><a href="https://build.microsoft.com/en-US">Microsoft Build</a>, 2019</li>
            </ul>
            <h3>Membership:</h3>
            <ul>
              <li>ACM <a href="https://sigchi.org">SIGCHI</a></li>
              <li><a href="https://pittcsc.org">Pitt Computer Science Club</a></li>
            </ul>
        </div>
    </>
  )
}
